<template>
      <b-modal id="modalLogoCompany" size="md" centered
          modal-class="custom-dialog"
          no-close-on-esc no-close-on-backdrop
          ok-variant="warning"
          ok-only
          :title="title"
          hide-footer
          >
          <div class="d-flex justify-content-center">
            <img :src="img" alt="Logo de empresa" class="mx-width-100">
          </div>
      </b-modal>
  </template> 
<script>
export default {
  name: 'modal-logo',
  props: ['title', 'img']
}
</script>

<style>
.mx-width-100{
  max-width: 100%;
}
</style>