<template>
  <div class="mt-50">
    <b-table
      id="my-table"
      :items="rows"
      :fields="schema"
      :per-page="perPage"
      :current-page="currentPage"
      small
    >
      <template #cell(status)="data">
          <div class="d-flex align-items-center">
              <span class="table_dot--company-history mr-50" :class="getStatusClass(data.item.status)"></span> 
              <span class="personalize-status">{{ data.item.status === 'Activo' ? 'Activa' : 'Inactiva' }}</span>
          </div>
      </template>
    </b-table>
    <b-pagination
      v-if="rows.length >= 10"
      :limit="10"
      align="center"
      class="mt-2"
      v-model="currentPage"
      :total-rows="rowsTable"
      :per-page="perPage"
      aria-controls="my-table"
      :first-text="$t('Primero')"
      :last-text="$t('Último')"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon"/>
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon"/>
      </template>
    </b-pagination>
  </div>
</template>

<script>
export default {
  name: 'activation-history',
  props: ['history'],
  data () {
    return {
      rows: [],
      schema: [],
      keyTableRender: 0,
      perPage: 10,
      currentPage: 1
    }
  },
  computed: {
    rowsTable() {
      return this.rows.length
    }
  },
  watch: {
    history () {
      this.rows = this.history
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.schema = [
        {label: 'Fecha', sortable: true, key: 'created_at'},
        {label: 'Estado', sortable: true, key: 'status', useSlot: true},
        {label: 'Descripcion', sortable: true, key: 'description'},
        {label: 'Usuario', sortable: true, key: 'user'}
      ]
      this.rows = this.history
    },
    getStatusClass(status) {
      return status === 'Activo' ? 'table_dot--company-ok' : 'table_dot--company-inactivated'
    }
  }
}
</script>

<style>
.table_dot--company-history{
  position: relative;
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

</style>