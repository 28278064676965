<template>
    <b-modal 
      id="modalExecutive" 
      :title="$t(`${!editExecutive ? 'Añadir ejecutivo envíame' : 'Editar ejecutivo envíame'}`)" 
      modal-class="custom-dialog dialog-600" 
      :ok-title="$t(`${!editExecutive ? 'Añadir' : 'Guardar'}`)" 
      ok-variant="warning" 
      ok-only 
      no-close-on-esc 
      no-close-on-backdrop 
      centered 
      @close="close" 
      @ok="ok" 
      :ok-disabled="loading"
      >
      <form-render
        :form.sync="form" 
        :fields="fields" 
        @send="onAccept" 
        containerButtonsClass="col-md-4 col-xl-3"
        class="mt-1"
        ref="formRenderExecutive"
        :key="keyFormRender" 
        />
    </b-modal>
</template> 
<script>
export default {
  name: 'modal-change-status',
  props: ['list_type_executives', 'executive'],
  data () {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      loading: true,
      editExecutive: false
    }
  },
  watch: {
    list_type_executives (value) {
      if (!!value) this.setOptionsTypeValidate()
    },
    executive (value) {
      this.form = {
        ...value
      }
      // Validamos si es una creación de empleado o edición de empleado
      this.editExecutive = !!value.id
      this.fields[0].disabled = false
      if (this.editExecutive) {
        this.fields[0].disabled = true
        this.setOptionsExecutives('option_executive', this.form.type, true)
      }
      this.loading = false
      this.keyFormRender++
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        {fieldType: 'FieldSelect', name: 'type', label: 'Tipo', containerClass: 'col-md-12 container-info', validation: 'required', change: this.setOptionsExecutives, disabled: false},  
        {fieldType: 'FieldSelect', name: 'option_executive', label: 'Nombre-Email', containerClass: 'col-md-12 container-info', validation: 'required', dependency: 'type', change: this.renderOptionExecutive}
      ]
      if (!!this.list_type_executives) this.setOptionsTypeValidate()
      this.loading = false
    },
    setOptionsTypeValidate () {
      const options = this.list_type_executives.missing_options
      this.setSelectOptions('type', { options })
      this.keyFormRender++
    },
    setOptionsExecutives (name, value, setExecutive) {
      if (!setExecutive) this.form.option_executive = undefined
      if (!!value) {
        const options = this.list_type_executives[value.id].map(item => {
          const text = item.text ? `${item.text} - ${item.email}` : `${item.first_name} ${item.last_name} - ${item.email}`
          return {
            ...item,
            text
          }
        })
        this.setSelectOptions('option_executive', { options })
      }
      this.keyFormRender++
    },
    // Problemas con validaciones, reendereo el form nuevamente para que no pase
    renderOptionExecutive () {
      this.keyFormRender++
    },
    setSelectOptions (name, { options }) {
      const index = this.getPositionFindIndex(this.fields, 'name', name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    },
    close () {
      this.$emit('update:executive', {})
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderExecutive.checkForm()
    },
    onAccept (data) {
      this.loading = true
      const payload = {
        executive_id: data.option_executive.id,
        type: data.type.id
      }
      this.$emit('savedExecutive', payload, this.editExecutive)   
    }
  }
}
</script>

<style>

</style>