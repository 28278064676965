<template>
  <div>
    <transition name="overlay-fade"><div class="overlay" v-if="showOverlay"></div></transition>
    <div class="d-flex align-items-center justify-content-between mb-1 pr-2 pl-2">
      <div class="d-flex align-items-center">
        <h1 class="title-section-info">Datos de la empresa</h1>
        <b-form-checkbox
          v-if="isAdmin"
          switch
          class="checkbox-switch switch-webhook checkbox-company"
          v-model="form.activated"
          @change="confirmChangeStatus()">
          <span class="personalize-status">{{ !!form.activated ? 'Activa' : 'Inactiva' }}</span>
        </b-form-checkbox>
        <div v-else class="d-flex align-items-center">
          <span class="table_dot--company mr-50" :class="getStatusClass(form.activated)"></span> 
          <span class="personalize-status">{{ !!form.activated ? 'Activa' : 'Inactiva' }}</span>
        </div>
      </div>
      <b-button 
        v-if="showBtn.edit && !loadingInfoCompany" 
        :disabled="!setUserInSession"
        class="d-flex align-items-center" 
        variant="outline-primary" 
        @click="enabledFields"
        >
        <feather-icon
          size="16"
          icon="Edit2Icon"
          class="mr-50"
        />Editar
      </b-button>
    </div>
    <div class="d-flex pr-3 pl-3 pt-1">
      <div class="file-drop-area mr-4">
        <div class="container_company--info">
          <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret  @shown="onDropdownOpen" @hidden="onDropdownClose">
            <template #button-content>
              <div :class="isOpenDropdown ? 'container_company--button' : ''">
                <img class="container_company--logo" :src="form.logo_url_v2 || IconLogoDefault" alt="Logo de la empresa" />
              </div>
            </template>
            <b-dropdown-item v-if="!!form.logo_url_v2" @click="openModalLogo">Ver Logo</b-dropdown-item>
            <b-dropdown-item @click="openFileInput">Cargar logo</b-dropdown-item>
          </b-dropdown>
        </div>
        <p class="mb-1 pt-1 text-center">PNG 300 px X 300px</p>
        <input
            ref="fileInput"
            v-if="!loading.logo"
            type="file"
            @change="changeCompanyLogo"
            name="file-input-company"
            id="file-input-company"
            class="file-input invisible d-none"
            accept=".png, jpg"
          />
      </div>
      <div class="w-100 pr-1">
        <form-render
            ref="formRenderCompany"
            @send="handleSubmitEdit"
            :key="keyFormRender" 
            class="px-0" 
            :fields="fields" 
            :form.sync="form"
            containerButtonsClass="col-md-4 col-xl-3 mt-2"
        />
        <div v-if="showBtn.saved" class="d-flex justify-content-end">
          <b-button @click="validateForm" variant="warning" :disabled="isOriginalForm || loading.savedChangeCompany">
            <span v-if="!loading.savedChangeCompany" class="mb-1">Guardar cambios</span>
            <span class="px-2" v-else ><b-spinner small /> </span>
          </b-button>
        </div> 
      </div>
    </div>
    <modal-logo 
      ref="modalLogoCompany" 
      title="Logo" 
      :img="form.logo_url_v2 || IconLogoDefault"
      />
    <modal-status-company 
      ref="modalStatusCompany" 
      :status="form.activated" 
      @originalStatus="originalStatus" 
      :setUserInSession="setUserInSession" 
      :reasons="{active: company_data.active_reasons, inactive: company_data.inactive_reasons}"
      @savedChangeStatus="savedChangeStatus"/>
  </div>
</template>

<script>
import BaseServices from '@/store/services/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import defaultLogo from '@/assets/images/icons/company_default_grey.png'
import ModalLogo from '../modals/ModalLogo.vue'
import ModalStatusCompany from '../modals/ModalStatusCompany.vue'
import isEqual from 'lodash/isEqual'
export default {
  name: 'principal-info',
  components: {ModalLogo, ModalStatusCompany},
  props: ['company_data', 'setUserInSession', 'loadingInfoCompany'],
  data () {
    return {
      form: {},
      IconLogoDefault: defaultLogo,
      loading: {
        logo: false,
        savedChangeCompany: false
      },
      keyFormRender: 0,
      fields: [],
      optionsShowCyberForm: [{id: '1', text: 'Mostrar Formulario Cyber'}],
      optionsTypes: [
        {id: 1, text: 'Empresa'},
        {id: 2, text: 'Seller'},
        {id: 3, text: 'Marketplace'}
      ],
      showBtn: {
        edit: true,
        saved: false
      },
      baseService: new BaseServices(this),
      showOverlay: false,
      isAdmin: false,
      isOpenDropdown: false,
      isOriginalForm: false
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries'
    })
  },
  watch: {
    countries () {
      this.setCountries()
    },
    setUserInSession (value) {
      if (!!value) this.setRoleUsers()
    },
    company_data () {
      this.setForm()
    },
    form: {
      handler (val, oldVal) {
        if (val && this.originalForm) {
          this.currentForm = {
            name1: val.name1 || '',
            show_cyber_form: val.show_cyber_form,
            webpage: val.webpage || ''
          }
          
          if (isEqual(this.currentForm, this.originalForm)) 
          {
            this.isOriginalForm = true
          } 
          else 
          {
            this.isOriginalForm = false
          }
        }
        
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        {fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-md-4 container-info', addFlags: true, validation: 'required', disabled: true},
        {fieldType: 'FieldInput', name: 'id', label: 'ID', containerClass: 'col-md-4 container-info', placeholder: 'Ingrese ID', validation: 'required', disabled: true},
        {fieldType: 'FieldInput', name: 'code', label: 'Código', containerClass: 'col-md-4 container-info', placeholder: 'Ingrese código', validation: 'required', disabled: true},  
        {fieldType: 'FieldInput', name: 'name1', label: 'Nombre', containerClass: 'col-md-4 container-info', placeholder: 'Ingrese nombre', validation: 'required', disabled: true},
        {fieldType: 'FieldSelect', name: 'type', label: 'Tipo', containerClass: 'col-md-4 container-info', validation: 'required', options: this.optionsTypes, disabled: true},
        {fieldType: 'FieldCheckbox', name: 'show_cyber_form', label: '', containerClass: 'hide', align: 'h', options: this.optionsShowCyberForm, disabled: true},
        {fieldType: 'FieldInput', name: 'webpage', label: 'Sitio web', containerClass: 'col-md-8 container-info', placeholder: 'Ingrese url', disabled: true}
      ]
      this.setForm()
      this.setCountries()
      if (!!this.setUserInSession) this.setRoleUsers()
    },
    setForm () {
      if (!!this.company_data) {
        const indexCountry = this.company_data.countries.findIndex((el) => el.id === this.company_data.company.country_id)
        this.form = {
          ...this.company_data.company,
          activated: this.company_data.company.activated === 1,
          show_cyber_form: this.company_data.company.show_cyber_form === 1,
          country: {
            ...this.company_data.countries[indexCountry],
            text: this.company_data.countries[indexCountry].name
          } 
        }
        this.originalForm = {
          name1: this.company_data.company.name1 || '',
          show_cyber_form: this.company_data.company.show_cyber_form === 1,
          webpage: this.company_data.company.webpage || ''
        }
      }
    },
    setRoleUsers () {
      this.isAdmin = ['admin', 'superadmin'].includes(this.$session.get('cas_user').role)
      this.isEcommerce = ['ecommerce'].includes(this.$session.get('cas_user').role)
      this.isMarketplace = ['marketplace'].includes(this.$session.get('cas_user').role)
      this.isSeller = ['seller'].includes(this.$session.get('cas_user').role)
      this.setDinamicField()
    },
    setDinamicField () {
      this.fields.map(field => {
        if (['show_cyber_form'].includes(field.name) && this.isAdmin) {
          field.containerClass = 'col-md-4 container-info justify-content-center mb-0'
        }
      })
    },
    setCountries () {
      if (this.countries && !!this.countries.length) {
        this.setSelectOptions('country', { options: this.countries })
      }
    },
    enabledFields () {
      this.disabledFields(false)
      this.showBtn.edit = false
      this.showBtn.saved = true
    },
    validateForm () {
      this.$refs.formRenderCompany.checkForm()
    },
    handleSubmitEdit () {
      if (!!this.company_data.validate_authority_contacts) {
        const dataToastNotification = {
          title: 'Oops',
          message: 'Debe incluir al menos un contacto tomador de decisión',
          variant: 'danger',
          icon: 'warning'
        }
        this.showToastNotificationError(dataToastNotification)
        this.$emit('openCollapseAuthorityContacts')
      } else {
        this.loading.savedChangeCompany = true
        const payload = this.m_findChangedFields(this.currentForm, this.originalForm)
        this.showOverlay = true
        this.baseService.callService('putEditCompanyNew', payload, {company_id: this.form.id})
          .then(resp => {
            this.$emit('getCompanyInfoEdition')
            this.disabledFields(true)
            this.showBtn.edit = true
            this.showBtn.saved = false 
            this.keyFormRender++
            this.$newSuccess('Excelente', 'La empresa se ha actualizado correctamente')
          })
          .catch(err => {
            const dataToastNotification = {
              title: 'Oops',
              message: `La empresa no se ha actualizado. Error: ${err.message}`,
              variant: 'danger',
              icon: 'warning'
            }
            this.showToastNotificationError(dataToastNotification)
          })
          .finally(() => {
            this.keyFormRender++
            this.loading.savedChangeCompany = false
            this.showOverlay = false
          })
      }
    },
    savedChangeStatus (data, status) {
      const payload = {
        user_email: data.user,
        action: !!status ? 'activate' : 'deactivate',
        code: data.reason.code,
        company_id: this.form.id
      }
      this.showOverlay = true
      this.baseService.callService('changeStatusCompany', payload, {company_id: this.form.id})
        .then(resp => {
          this.$emit('refreshCompanyData', resp.data.status_logs, {reason_id: data.reason.id, activated: !!status})
          this.originalStatus(status)
          this.$bvModal.hide('modalStatusCompany')
          this.$refs.modalStatusCompany.form.reason = null
          this.keyFormRender++
          this.$newSuccess('Excelente', `Se ${status ? 'activo' : 'desactivo'} la empresa correctamente`)
        })
        .catch(err => {
          const dataToastNotification = {
            title: 'Oops',
            message: `No se pudo realizar el cambio de estado. Error: ${err.message}`,
            variant: 'danger',
            icon: 'warning'
          }
          this.showToastNotificationError(dataToastNotification)
        })
        .finally(() => {
          this.keyFormRender++
          this.$refs.modalStatusCompany.loading = false
          this.showOverlay = false
        })
    },
    disabledFields (disabled) {
      const alwaysDisabledField = ['id', 'country', 'code', 'type']
      const fieldsFilter = this.fields.filter(field => !alwaysDisabledField.includes(field.name))
      fieldsFilter.map(item => {
        item.disabled = disabled
      })
    },
    confirmChangeStatus () {
      this.$bvModal.show('modalStatusCompany')
    },
    changeCompanyLogo (event) {
      const file = event.target.files[0]
      const data = {
        name: 'changeLogo',
        params: {company_id: this.form.id},
        data: { logo: file, type: 'profile' }
      }
      this.showOverlay = true
      this.baseService
        .callUploadFileCustomParams(data.name, data.data, data.params)
        .then((response) => {
          this.form.logo_url_v2 = response.data.company.logo_url_v2
          this.keyFormRender++
          this.$newSuccess('Excelente', 'El logo se ha actualizado correctamente')
        })
        .catch((err) => {
          const dataToastNotification = {
            title: 'Oops',
            message: `El logo no se ha actualizado Error: ${err.message}`,
            variant: 'danger',
            icon: 'warning'
          }
          this.showToastNotificationError(dataToastNotification)
        })
        .finally(() => {
          this.showOverlay = false
          this.loading.logo = false
        })
    },
    showToastNotificationError(dataToastNotification) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: dataToastNotification.title,
          icon: dataToastNotification.icon,
          variant: dataToastNotification.variant,
          text: dataToastNotification.message
        }
      })
    },
    openFileInput () {
      this.$refs.fileInput.click()
    },
    openModalLogo () {
      this.$bvModal.show('modalLogoCompany')
    },
    setSelectOptions (name, { options }) {
      const index = this.getPositionFindIndex(this.fields, 'name', name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    },
    getStatusClass(activated) {
      return activated ? 'table_dot--company-ok' : 'table_dot--company-inactivated'
    },
    originalStatus (status) {
      this.form.activated = status
      this.keyFormRender++
    },
    onDropdownOpen() {
      this.isOpenDropdown = true
    },
    onDropdownClose() {
      this.isOpenDropdown = false
    }
  }
}
</script>

<style>
.container_company--info {
  width: 206px;
  height: 208px;
}
.container_company--logo {
  height: 208px;
  width: 206px;
  max-height: 208px;
  max-width: 206px;
  border-radius: 10px;
}
.container_company--info button {
  padding: 0;
  border: none;
}
.container_company--info ul {
  position: absolute;
  will-change: transform !important;
  top: 100px !important;
  left: 110px !important;
  transform: none !important;
}
.checkbox-company .custom-control-label {
  margin-bottom: 0 !important;
}
.title-section-info{
  font-size: 20px;
  font-weight: bold;
  margin-right: 1.5rem;
  margin-bottom: 0;
}
.table_dot--company{
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.table_dot--company-ok{
  background-color:#5CB30B
}
.table_dot--company-inactivated{
  background-color:#D1DADE
}
.table_dot--info {
  font-size: 0.857rem;
}
.container_company--button {
  position: relative;
}
.container_company--button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  z-index: 2;
}
.switch-webhook 
.custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  border-color: #1E8E3E;
  background-color: #1E8E3E;
}
</style>