<template>
  <b-modal 
      id="modalExpander" 
      modal-class="dialog-1000" 
      no-close-on-esc
      no-close-on-backdrop
      centered
      hide-footer 
      @close="close"
    >
    <template #modal-header>
        <div class="d-flex align-items-center container-header-modal">
          <h5 class="modal-title">{{ formExpander.title }}</h5>
          <feather-icon
            class="icon-hover push-right text-primary"
            icon="Minimize2Icon"
            size="20"
            @click="close()"
          />
        </div>
      </template>
      <b-form-textarea 
        v-if="!!formExpander"
        class="mt-1" 
        :value="formExpander.value" 
        :disabled="disabledModal" 
        rows="30"
        @input="updateTextareaValue"
        :placeholder="`Ingrese ${formExpander.title}`"
        />
        <div v-if="!disabledModal" class="d-flex justify-content-end mt-1">
          <b-button @click="savedFieldsExpander" variant="warning">
            <span>Guardar cambios</span>
          </b-button>
        </div> 
    </b-modal>
</template>

<script>
export default {
  name: 'modal-expander',
  props: ['fieldExpander', 'disabledModal'],
  data () {
    return {
      formExpander: null
    }
  },
  watch: {
    fieldExpander (value) {
      if (!!value) {
        this.formExpander = value
      }
    }
  },
  mounted () {
    if (!!this.fieldExpander) {
      this.formExpander = this.fieldExpander
    }
  },
  methods: {
    close() {
      this.$bvModal.hide('modalExpander')
    },
    updateTextareaValue(value) {
      this.formExpander.value = value
    },
    savedFieldsExpander () {
      this.$emit('updateField', this.formExpander)
      this.close()
    }
  }
}
</script>

<style>
.container-header-modal{
  width: 100%;
}
.icon-hover:hover {
  cursor: pointer;
}
</style>