<template>
    <b-modal 
      id="modalAuthorityContact" 
      :title="$t(`${!editAuthorityContact ? 'Añadir tomador de decisión' : 'Editar tomador de decisión'}`)" 
      modal-class="custom-dialog dialog-600" 
      :ok-title="$t(`${!editAuthorityContact ? 'Añadir' : 'Guardar'}`)" 
      ok-variant="warning" 
      ok-only 
      no-close-on-esc 
      no-close-on-backdrop 
      centered 
      @close="close" 
      @ok="ok" 
      :ok-disabled="loading"
      >
      <form-render
        :form.sync="form" 
        :fields="fields" 
        @send="onAccept" 
        containerButtonsClass="col-md-4 col-xl-3"
        class="mt-1"
        ref="formRenderAuthorityContact"
        :key="keyFormRender" 
        />
    </b-modal>
</template> 
<script>
export default {
  name: 'modal-change-status',
  props: ['authorityContact', 'contact_roles'],
  data () {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      loading: true,
      editAuthorityContact: false
    }
  },
  watch: {
    contact_roles (value) {
      if (!!value) this.setOptionsRoles()
    },
    authorityContact (value) {
      this.form = {
        ...value
      }
      // Validamos si es una creación de empleado o edición de empleado
      this.editAuthorityContact = !!value.id
      this.loading = false
      this.keyFormRender++
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        {fieldType: 'FieldInput', name: 'full_name', label: 'Nombre', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese Nombre y Apellido', validation: 'required'},
        {fieldType: 'FieldSelect', name: 'role', label: 'Cargo', containerClass: 'col-md-12 container-info', validation: 'required'},
        {fieldType: 'FieldInput', name: 'email', type: 'email', label: 'Mail', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese Mail de contacto', validation: 'required|email'},
        {fieldType: 'FieldInput', name: 'phone', label: 'Teléfono', type: 'number', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese Teléfono de contacto', validation: 'required'}
       
      ]
      if (!!this.contact_roles) this.setOptionsRoles()
      this.loading = false
    },
    setOptionsRoles () {
      const options = this.contact_roles
      this.setSelectOptions('role', { options })
    },
    setSelectOptions (name, { options }) {
      const index = this.getPositionFindIndex(this.fields, 'name', name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    },
    close () {
      this.$emit('update:authorityContact', {})
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderAuthorityContact.checkForm()
    },
    onAccept (data) {
      this.loading = true
      const payload = {
        email: data.email,
        full_name: data.full_name,
        phone: data.phone,
        role_id: data.role.id 
      }
      if (this.editAuthorityContact) payload.id = this.form.id
      this.$emit('savedAuthorityContact', payload, this.editAuthorityContact)   
    }
  }
}
</script>

<style>

</style>