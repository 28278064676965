<template>
    <b-modal id="modalStatusCompany" 
        centered
        modal-class="custom-dialog dialog-700"
        no-close-on-esc 
        no-close-on-backdrop
        ok-variant="warning"
        ok-only
        :title="`Motivo ${!status ? 'inactivación': 'activación'}`"
        hide-footer
        @close="close"
        >
        <b-card class="mt-1">
          <p class="mb-0"><small class="mb-0">Usted está a punto de {{ !status ? 'inactivar': 'activar' }} esta empresa.</small></p>
          <p><small>Indique el motivo para continuar.</small></p>
          <form-render
            ref="formRenderStatusCompany"
            :key="keyFormRender" 
            class="px-0 mt-2" 
            :fields="fields" 
            :form.sync="form"
            containerButtonsClass="col-md-4 col-xl-3 mt-2" 
            @send="handleSubmitChangeStatus"
          />
          <div class="d-flex justify-content-end">
            <b-button @click="validateForm" variant="warning" :disabled="loading">
              <span v-if="!loading" class="mb-1">Confirmar</span>
              <span class="px-2" v-else ><b-spinner small /> </span>
            </b-button>
          </div>
        </b-card>
    </b-modal>
</template> 
<script>
export default {
  name: 'modal-change-status',
  props: ['status', 'reasons', 'setUserInSession'],
  data () {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      loading: false
    }
  },
  watch: {
    status (value) {
      this.setReasons(value)
    },
    setUserInSession (value) {
      if (!!value) this.form.user = this.$session.get('cas_user').email
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        {fieldType: 'FieldInput', name: 'user', label: 'Usuario', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese usuario', validation: 'required', disabled: true},  
        {fieldType: 'FieldInput', name: 'date', label: 'Fecha', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese fecha', validation: 'required', disabled: true},
        {fieldType: 'FieldSelect', name: 'reason', label: 'Motivo', containerClass: 'col-md-12 container-info', validation: 'required', change: this.renderOptionReason}
      ] 
      const currentDate = new Date()
      this.form.date = this.$options.filters.moment(currentDate, 'DD/MM/YYYY HH:mm')
      if (!!this.setUserInSession) this.form.user = this.$session.get('cas_user').email
    },
    // Problemas con validaciones, reendereo el form nuevamente para que no pase
    renderOptionReason () {
      this.keyFormRender++
    },
    setReasons (value) {
      const reasons = value ? this.reasons.active : this.reasons.inactive
      this.setSelectOptions('reason', { options: reasons })
    },
    setSelectOptions (name, { options }) {
      const index = this.getPositionFindIndex(this.fields, 'name', name)
      if (index !== -1) {
        this.fields[index].options = options
        this.keyFormRender++
      }
    },
    getPositionFindIndex (origin, type,  name) {
      return origin.findIndex(el => el[type] === name)
    },
    close () {
      this.$emit('originalStatus', !this.status)
      this.form.reason = null
      this.keyFormRender++
    },
    validateForm () {
      this.$refs.formRenderStatusCompany.checkForm()
    },
    handleSubmitChangeStatus (data) {
      this.loading = true
      this.$emit('savedChangeStatus', data, this.status)
    }
  }
}
</script>

<style>

</style>