<template>
  <div>
    <div v-if="loading.companyInfoSkeleton">
      <b-skeleton-img class="mt-1" no-aspect height="300px"></b-skeleton-img>
      <b-skeleton class="mt-1" type="input"></b-skeleton>
      <b-skeleton class="mt-1" type="input"></b-skeleton>
      <b-skeleton class="mt-1" type="input"></b-skeleton>
      <b-skeleton class="mt-1" type="input"></b-skeleton>
      <b-skeleton class="mt-1" type="input"></b-skeleton>
    </div>
    <div v-else>
      <principal-info
        :company_data="company_data" 
        :setUserInSession="setUserInSession" 
        :loadingInfoCompany="loading.companyInfo"
        @getCompanyInfoEdition="getCompanyInfoEdition" 
        @refreshCompanyData="refreshCompanyData"
        @openCollapseAuthorityContacts="openCollapseAuthorityContacts"
      />
      <app-collapse accordion class="row block pr-2 pl-2 collapse-company">
        <app-collapse-item 
          title="Ejecutivos envíame" 
          :autoClose="false"
          :customArrowDirection="true"
          >
          <table-executives
          :company_data="company_data" 
          :setUserInSession="setUserInSession" />
        </app-collapse-item>
        <app-collapse-item 
          ref="collapseAuthorityContacts"
          v-if="showTabAuthorityContacts"
          title="Contactos tomadores de decisión" 
          :autoClose="false"
          :customArrowDirection="true">
          <table-authority-contact
          :authority_contact_roles="company_data.authority_contact_roles"
          :setUserInSession="setUserInSession"
          @validateAuthorityContacts="validateAuthorityContacts"
          />
        </app-collapse-item>
        <app-collapse-item 
          v-if="showTabBilling || !!isAdmin" 
          title="Datos de facturación" 
          :autoClose="false"
          :customArrowDirection="true">
          <billing-information
          :setUserInSession="setUserInSession"
          :company_data="company_data"
          :refresh_company_data="refresh_company_data"
          @notDirectBilling="notDirectBilling"
          @setTabAuthorityContacts="setTabAuthorityContacts"
          @validateAuthorityContacts="validateAuthorityContacts"/>
        </app-collapse-item>
        <app-collapse-item 
          title="Detalles operación" 
          :autoClose="false"
          :customArrowDirection="true"
          >
          <operation-details
            :company_data="company_data" 
            :setUserInSession="setUserInSession"
            :loadingInfoCompany="loading.companyInfo"
            @changeTabNotifications="changeTabNotifications"
            @getCompanyInfoEdition="getCompanyInfoEdition"
            @openCollapseAuthorityContacts="openCollapseAuthorityContacts"/>
        </app-collapse-item>
        <app-collapse-item 
          title="Historial de Activación" 
          :autoClose="false"
          :customArrowDirection="true">
          <table-activation-history 
          :history="company_data.company_history"/>
        </app-collapse-item>
      </app-collapse>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PrincipalInfo from './components/sections/PrincipalInfo.vue'
import TableExecutives from './components/sections/TableExecutives.vue'
import TableAuthorityContact from './components/sections/TableAuthorityContact.vue'
import BillingInformation from './components/sections/BillingInformation.vue'
import OperationDetails from './components/sections/OperationDetails.vue'
import TableActivationHistory from './components/sections/TableActivationHistory.vue'

export default {
  name: 'companyInfoNew',
  components: {
    PrincipalInfo,
    TableExecutives,
    TableAuthorityContact,
    BillingInformation,
    OperationDetails,
    TableActivationHistory
  },
  data () {
    return {
      company_id: this.$route.params?.id,
      reasons: null,
      loading: {
        companyInfo: true,
        companyInfoSkeleton: true
      },
      setUserInSession: false,
      company_data: null,
      showTabBilling: true,
      isAdmin: false,
      showTabAuthorityContacts: false,
      refresh_company_data: null
    }
  },
  watch: {
    mySession () {
      if (this.mySession?.id && this.companyInfo) {
        this.verifySession()
      }
    },
    companyInfo() {
      if (!!this.companyInfo) {
        this.company_data = this.companyInfo.data
        this.company_data.validate_authority_contacts = false
        this.loading.companyInfoSkeleton = false
      }
      if (this.mySession?.id && this.companyInfo) {
        this.verifySession()
      }
    },
    generalLoading: {
      handler () {
        this.loading.companyInfo = !!this.generalLoading.getCompanyInformation
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      companyInfo: 'getCompanyInformation',
      generalLoading: 'getLoading'
    })
  },
  mounted () {
    this.getCompanyInfo()
  },
  methods: {
    verifySession() {
      if (this.mySession?.id) {
        this.setUserInSession = true
        this.isAdmin = ['admin', 'superadmin'].includes(this.$session.get('cas_user').role)
      } else {
        setTimeout(() => {
          this.verifySession()
        }, 200)
      }
    },
    getCompanyInfo() {
      this.$store.dispatch('fetchService', { name: 'getCompanyInformation', params: {company_id: this.company_id}})
    },
    changeTabNotifications () {
      this.$emit('changeTabNotifications')
    },
    getCompanyInfoEdition () {
      this.getCompanyInfo()
    },
    notDirectBilling (value) {
      this.showTabBilling = !value
      this.showTabAuthorityContacts = !value
    },
    refreshCompanyData (data, refresh) {
      this.company_data.company_history = data
      this.refresh_company_data = refresh
    },
    validateAuthorityContacts (data) {
      this.company_data.validate_authority_contacts = data
    },
    setTabAuthorityContacts (data) {
      this.showTabAuthorityContacts = data
    },
    openCollapseAuthorityContacts () {
      this.$refs.collapseAuthorityContacts.openCollapseManually()
    }
  }

}
</script>

<style lang='scss'>
 .loading-spinner-company {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(77 77 77 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40000
  }
  .custom-spinner {
    color: #fff;
    width: 10rem; 
    height: 10rem;
  }
  .collapse-company {
    .card-header {
      justify-content: flex-start;
    }

    [data-toggle='collapse'] {
      &:after {
        position: initial;
        margin-top: 0;
        margin-left: 0.5rem;
      }

    }
  }
  .hide {
    display: none;
  }
</style>