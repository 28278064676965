<template>
  <div>
    <div v-if="!setUserInSession" class="loading-spinner d-flex justify-content-center">
      <b-spinner variant="primary"/>
    </div>
    <div v-else>
      <div class="d-flex justify-content-end">
          <b-button 
              v-if="showNewExecutive"
              :disabled="loading"
              class="d-flex align-items-center" 
              variant="outline-primary" 
              @click="openModalCreateExecutive"
          >
              <feather-icon
                  size="16"
                  icon="PlusIcon"
                  class="mr-50"
              /> Añadir Ejecutivo
          </b-button>
      </div>
      <div class="table-executives pl-50 pr-50">
        <table-render 
          :key="keyTableRender" 
          :rows="rows" 
          :schema="schema" 
          :stickyHeader="`calc(100vh - 2.5rem)`"
          :tableClass="'with-scroll'"
          class="p-1"
          :actions="actions"
          :loading="loading"
        />
      </div>
      <modal-executive 
          ref="modalExecutive" 
          :list_type_executives="list_type_executives" 
          @savedExecutive="savedExecutive"  
          :executive="currentExecutive"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BaseServices from '@/store/services/index'
import ModalExecutive from '../modals/ModalExecutive.vue'
export default {
  name: 'table-executives',
  props: ['company_data', 'setUserInSession'],
  components: {ModalExecutive},
  data () {
    return {
      keyTableRender: 0,
      rows: [],
      schema: [],
      actions: [],
      list_type_executives: null,
      baseService: new BaseServices(this),
      company_id: this.$route.params?.id,
      CONDITIONS: Object.freeze({
        executive	: 'Ejecutivo Comercial',
        operational_escort: 'Escolta Operacional',
        collection_executive: 'Ejecutivo Cobranzas',
        SUCCESS	: 'success',
        ERROR: 'error'
      }),
      showNewExecutive: false,
      currentExecutive: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading',
      executives: 'getExecutivesCompany'
    })
  },
  watch: {
    executives (value) {
      this.setRows(value)
      this.setListExecutives(value)
    },
    setUserInSession (value) {
      if (!!value) this.setRoleUsers()
    },
    generalLoading: {
      handler() {
        this.loading = !!this.generalLoading.getExecutivesCompany
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.setSchema()
      this.getExecutives()
      if (!!this.setUserInSession) this.setRoleUsers()
    },
    setRoleUsers () {
      this.isAdmin = ['admin', 'superadmin'].includes(this.$session.get('cas_user').role)
      if (this.isAdmin) {
        this.schema.push({label: 'Acciones', key: 'actions', class: 'text-center', style: { width: '20%' } })
        this.actions = [
          { action: id => this.openModalEditExecutve(id), icon: 'Edit2Icon', color: 'primary', text: 'Editar' },
          { action: id => this.confirmDeleteExcutive(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
        ]
        this.showNewExecutive = this.rows.length < 3
      }
    },
    setSchema () {
      this.schema = [
        {label: 'Nombre', sortable: true, key: 'name'},
        {label: 'Tipo', sortable: true, key: 'type'},
        {label: 'Mail', sortable: true, key: 'email'}
      ]
    },
    setListExecutives (value) {
      const typesOfCurrentExecutives = value.map(item => item.position)
      this.list_type_executives = {
        executive: this.filterByCountryId(this.company_data.executives),
        operational_escort: this.filterByCountryId(this.company_data.operational_escorts),
        collection_executive: this.filterByCountryId(this.company_data.collection_executives)
      }
      const filterMissingTypes = Object.keys(this.list_type_executives).filter(item => !typesOfCurrentExecutives.includes(item))
      const optionForTypeSelector = filterMissingTypes.map(element => {
        return {
          id: element, text: this.CONDITIONS[element]
        }
      })
      this.list_type_executives.missing_options = optionForTypeSelector
    },
    filterByCountryId(array) {
      return array.filter(item => item.country_id === this.company_data.company.country.id)
    },
    getExecutives () {
      this.$store.dispatch('fetchService', { name: 'getExecutivesCompany', params: {company_id: this.company_id}})
    },
    setRows (value) {
      this.rows = value.map(executive => {
        return {
          ...executive,
          type: this.CONDITIONS[executive.position]
        }
      })
    },
    openModalCreateExecutive () {
      this.currentExecutive = {}
      this.$bvModal.show('modalExecutive')
    },
    openModalEditExecutve (id) {
      const user = this.rows.filter(row => row.id === id)[0]
      this.currentExecutive = {
        ...user,
        type: {id: user.position, text: this.CONDITIONS[user.position]},
        option_executive: {id: user.id, text: `${user.name} - ${user.email}`}
      }
      this.$bvModal.show('modalExecutive')
    },
    confirmDeleteExcutive (id) {
      this.$confirm(this.$t('¿Está seguro que desea eliminar el ejecutivo?'), () => this.deleteExecutive(id))
    },
    savedExecutive (data, edit) {
      if (!!edit) {
        this.updateExecutive(data)
      }
      else {
        this.addExecutive(data)
      }
    },
    updateExecutive (data) {
      const queryParams = {
        ...data
      }
      const message = {
        success: 'Se actualizó el ejecutivo',
        error: 'No se pudo actualizar el ejecutivo'
      }
      this.postExecutive(queryParams, message)
    },
    addExecutive (data) {
      const queryParams = {
        ...data
      }
      const message = {
        success: 'Se agrego el ejecutivo',
        error: 'No se pudo agregar el ejecutivo'
      }
      this.postExecutive(queryParams, message)
    },
    postExecutive (queryParams, message) {
      this.$store.dispatch('fetchService', { 
        name: 'postExecutiveCompany', 
        queryParams, 
        params: {company_id: this.company_id}, 
        onSuccess: () => this.handleResponseStatus(this.CONDITIONS.SUCCESS, message.success), 
        onError: (err) => this.handleResponseStatus(this.CONDITIONS.ERROR, message.error, err), hideAlert: true
      })
    },
    deleteExecutive (id) {
      const executive = this.rows.filter(el => el.id === id)[0]
      const message = {
        success: 'Se elimino el ejecutivo',
        error: 'No se pudo eliminar el ejecutivo'
      }
      this.$store.dispatch('fetchService', { 
        name: 'deleteExecutiveCompany', 
        queryParams: {
          type: executive.position,
          executive_id: executive.id.toString()
        }, 
        params: {company_id: this.company_id}, 
        onSuccess: () => this.handleResponseStatus('success', message.success), 
        onError: (err) => this.handleResponseStatus(this.CONDITIONS.ERROR, message.error, err), 
        hideAlert: true
      })
    },
    handleResponseStatus (type, message, err) {
      if (type === this.CONDITIONS.SUCCESS) {
        this.getExecutives()
        this.$bvModal.hide('modalExecutive')
        this.$newSuccess('Excelente', message)
      } else {
        const dataToastNotification = {
          title: 'Oops',
          message: `${message}. Error: ${err.message}`,
          variant: 'danger',
          icon: 'warning'
        }
        this.showToastNotificationError(dataToastNotification)
      }
      this.$refs.modalExecutive.loading = false
    },
    showToastNotificationError(dataToastNotification) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: dataToastNotification.title,
          icon: dataToastNotification.icon,
          variant: dataToastNotification.variant,
          text: dataToastNotification.message
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>

.table-executives {
  .table-render-skeleton{
    overflow-x: hidden;
  }
}

</style>