<template>
  <div>
    <div v-if="!setUserInSession" class="loading-spinner d-flex justify-content-center">
      <b-spinner variant="primary"/>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between align-items-center">
          <p class="m-0 p-0"><span class="text-danger">*</span> Debe incluir al menos un contacto tomador de decisiones</p>
          <b-button 
              :disabled="loading"
              class="d-flex align-items-center" 
              variant="outline-primary" 
              @click="openModalCreateAuthorityContact"
          >
              <feather-icon
                  size="16"
                  icon="PlusIcon"
                  class="mr-50"
              /> Añadir Contacto
          </b-button>
      </div>
      <div class="table-authority pl-50 pr-50">
        <table-render 
          :key="keyTableRender" 
          :rows="rows" 
          :schema="schema" 
          :stickyHeader="`calc(100vh - 2.5rem)`"
          :tableClass="'with-scroll'"
          class="p-1"
          :actions="actions"
          :loading="loading"
        />
      </div>
      <modal-authority-contact 
          ref="modalAuthorityContact"
          :contact_roles="contact_roles"
          :authorityContact="authorityContact"
          @savedAuthorityContact="savedAuthorityContact"/>
    </div>
  </div>
  </template>


<script>
import { mapGetters } from 'vuex'
import ModalAuthorityContact from '../modals/ModalAuthorityContact.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'table-authority-contact',
  props: ['authority_contact_roles', 'setUserInSession'],
  components: {ModalAuthorityContact},
  data () {
    return {
      keyTableRender: 0,
      rows: [],
      schema: [],
      actions: [],
      company_id: this.$route.params?.id,
      authorityContact: null,
      contact_roles: null,
      loading: false,
      CONDITIONS: Object.freeze({
        SUCCESS	: 'success',
        ERROR: 'error'
      })
    }
  },
  computed: {
    ...mapGetters({
      generalLoading: 'getLoading',
      authorityContacts: 'getAuthorityContacts'
    })
  },
  watch: {
    authorityContacts (value) {
      this.setRows(value)
    },
    generalLoading: {
      handler() {
        this.loading = !!this.generalLoading.getAuthorityContacts
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.setSchema()
      this.setAuthorityContact()
    },
    setSchema () {
      this.schema = [
        {label: 'Nombre', sortable: true, key: 'full_name'},
        {label: 'Cargo', sortable: true, key: 'role.name'},
        {label: 'Mail', sortable: true, key: 'email'},
        {label: 'Teléfono', sortable: true, key: 'phone'},
        {label: 'Acciones', key: 'actions', class: 'text-center', style: { width: '20%' } }
      ]
      this.actions = [
        { action: id => this.openModalEditAuthorityContact(id), icon: 'Edit2Icon', color: 'primary', text: 'Editar' },
        { action: id => this.confirmDeleteAuthorityContact(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
      ]
    },
    setAuthorityContact () {
      this.$store.dispatch('fetchService', { name: 'getAuthorityContacts', params: {company_id: this.company_id}})
    },
    setRows (data) {
      this.contact_roles = this.authority_contact_roles.map(item => {
        return {
          ...item, 
          text: item.name
        }
      })
      const authority_contacts = data.map(item => {
        const allNull = Object.values(item.role).every(value => value === null)
        return {
          ...item,
          role: allNull ? null : {...item.role, text: item.role?.name}
        }
      })
      this.rows = authority_contacts
      const validateAuthorityContacts = this.rows.length === 0
      this.validateAuthorityContacts(validateAuthorityContacts)
    },
    validateAuthorityContacts (data) {
      this.$emit('validateAuthorityContacts', data)
      if (data) {
        const dataToastNotification = {
          title: 'Importante',
          message: 'Para editar la empresa debe incluir al menos un contacto tomador de decisión',
          variant: 'warning',
          icon: 'warning'
        }
        this.showToastNotificationError(dataToastNotification)
      }
    },
    savedAuthorityContact (data, edit) {
      if (!!edit) {
        this.updateAuthorityContact(data)
      }
      else {
        this.addAuthorityContact(data)
      }
    },
    updateAuthorityContact (data) {
      const queryParams = {
        ...data
      }
      const message = {
        success: 'Se actualizo el contacto',
        error: 'No se pudo actualizar el contacto'
      }
      this.$store.dispatch('fetchService', { 
        name: 'putAuthorityContact', 
        queryParams, 
        params: {company_id: this.company_id}, 
        onSuccess: () => this.handleResponseStatus(this.CONDITIONS.SUCCESS, message.success), 
        onError: (err) => this.handleResponseStatus(this.CONDITIONS.ERROR, message.error, err), 
        hideAlert: true
      })
    },
    addAuthorityContact (data) {
      const queryParams = {
        ...data
      }
      const message = {
        success: 'Se agrego el contacto',
        error: 'No se pudo agregar el contacto'
      }
      this.$store.dispatch('fetchService', { 
        name: 'postAuthorityContact', 
        queryParams, 
        params: {company_id: this.company_id}, 
        onSuccess: () => this.handleResponseStatus(this.CONDITIONS.SUCCESS, message.success), 
        onError: (err) => this.handleResponseStatus(this.CONDITIONS.ERROR, message.error, err), hideAlert: true
      })
    },
    handleResponseStatus (type, message, err) {
      if (type === this.CONDITIONS.SUCCESS) {
        this.setAuthorityContact()
        this.$bvModal.hide('modalAuthorityContact')
        this.$newSuccess('Excelente', message)
      } else {
        const dataToastNotification = {
          title: 'Oops',
          message: `${message}. Error: ${err.message}`,
          variant: 'danger',
          icon: 'warning'
        }
        this.showToastNotificationError(dataToastNotification)
      }
      this.$refs.modalAuthorityContact.loading = false
    },
    showToastNotificationError(dataToastNotification) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: dataToastNotification.title,
          icon: dataToastNotification.icon,
          variant: dataToastNotification.variant,
          text: dataToastNotification.message
        }
      })
    },
    openModalCreateAuthorityContact () {
      this.authorityContact = {}
      this.$bvModal.show('modalAuthorityContact')
    },
    openModalEditAuthorityContact (id) {
      this.authorityContact = this.rows.filter(row => row.id === id)[0]
      this.$bvModal.show('modalAuthorityContact')
    },
    confirmDeleteAuthorityContact (id) {
      this.$confirm(this.$t('¿Está seguro que desea eliminar el contacto?'), () => this.deleteAuthorityContact(id))
    },
    deleteAuthorityContact (id) {
      const message = {
        success: 'Se eliminó el contacto',
        error: 'No se pudo eliminar el contacto'
      }
      this.$store.dispatch('fetchService', { 
        name: 'deleteAuthorityContact', 
        queryParams: {contact_id: id.toString()}, 
        params: {company_id: this.company_id}, 
        onSuccess: () => this.handleResponseStatus('success', message.success), 
        onError: (err) => this.handleResponseStatus(this.CONDITIONS.ERROR, message.error, err), 
        hideAlert: true
      })
    }
  }
}
</script>

<style lang='scss' scoped>

.table-authority {
  .table-render-skeleton{
    overflow-x: hidden;
  }
}

</style>