<template>
  <div>
    <div v-if="!setUserInSession" class="loading-spinner d-flex justify-content-center">
      <b-spinner variant="primary"/>
    </div>
    <div v-else>
      <transition name="overlay-fade"><div class="overlay" v-if="showOverlay"></div></transition>
        <div class="position-relative mt-2">
            <b-button 
            v-if="showBtn.edit && !!isAdmin" 
            class="d-flex align-items-center position-absolute custom-position-edit-billing" 
            variant="outline-primary" 
            @click="enabledFields">
                <feather-icon
                size="16"
                icon="Edit2Icon"
                class="mr-50"
                />Editar
            </b-button>
            <form-render
                ref="formRenderBilling"
                @send="handleSubmit"
                :key="keyFormRender" 
                class="px-0" 
                :fields="fields" 
                :form.sync="form"
                containerButtonsClass="col-md-4 col-xl-3 mt-2" 
            >
            <template #contacts>
              <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column">
                <span class="mb-50 font-weight-bold">Contactos facturación</span>
                <p class="m-0 p-0 ml-2"><span class="text-danger">*</span> Debe incluir al menos un contacto</p>
              </div>
              <b-button 
                  v-if="!!showBtn.saved"
                  class="d-flex align-items-center" 
                  variant="outline-primary" 
                  @click="openModalCreateBilling"
              >
                  <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="mr-50"
                  /> Añadir Contacto
              </b-button>
            </div>
            <div class="table-billing pl-50 pr-50">
              <table-render 
                :key="keyTableRender" 
                :rows="rows" 
                :schema="schema" 
                :stickyHeader="`calc(100vh - 2.5rem)`"
                :tableClass="'with-scroll'"
                class="p-1"
                :actions="actions"
                :loading="loading.billingContacts"
              />
            </div>
            </template>
            </form-render>
            <div v-if="showBtn.saved" class="d-flex justify-content-end mb-2">
            <b-button @click="validateForm" variant="warning" :disabled="isOriginalForm || loading.savedChangeBilling">
                <span v-if="!loading.savedChangeBilling" class="mb-1">{{ createBillingData ? 'Crear' : 'Guardar cambios'}}</span>
                <span class="px-2" v-else ><b-spinner small /> </span>
            </b-button>
            </div> 
        </div>
        <modal-billing-contact 
          ref="modalBillingContact"  
          :billingContact="currentBillingContact"
          @savedBillingContact="savedBillingContact"
          :rows="rows" />
    </div>
  </div>
</template>

<script>
import ModalBillingContact from '../modals/ModalBillingContact.vue'
import BaseServices from '@/store/services/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import isEqual from 'lodash/isEqual'
import _ from 'lodash'
export default { 
  name:'billing-information',
  props: ['company_data', 'setUserInSession', 'refresh_company_data'],
  components: {ModalBillingContact},
  data () {
    return {
      showOverlay: false,
      showBtn: {
        edit: true,
        saved: false
      },
      loading: {
        savedChangeBilling: false,
        billingContacts: false
      },
      keyFormRender: 0,
      fields: [],
      form: {
        contacts: []
      },
      optionsOC: [{id: false, text: 'OC'}],
      optionsBilling: [{id: false, text: 'Facturación Directa'}],
      optionsInvoices: [{id: false, text: 'Mostrar Facturas'}],
      keyTableRender: 0,
      rows: [],
      schema: [],
      actions: [],
      CONDITIONS: Object.freeze({
        CHILE	: 'cl',
        SECONDARY: 'secondary',
        PRIMARY: 'primary',
        DANGER: 'danger',
        EDIT: 'Editar',
        PAYMENTCOMMITMENT: 'compromiso de pago',
        LOAD: 'cargar',
        SAVE: 'guardar'

      }),
      baseService: new BaseServices(this),
      company_id: this.$route.params?.id,
      currentBillingContact: null,
      createBillingData: false,
      isAdmin: false,
      isOriginalForm: false,
      enabledFieldOverdue: false
    }
  },
  watch: {
    form: {
      handler (val, oldVal) {   
        if (val && this.originalForm) {
          this.currentForm = {
            ...val,
            credit_days: parseInt(val.credit_days)
          }
          const originalForm = {
            ...this.originalForm
          }
          // Extraigo el array de contactos
          this.contactsForm = this.currentForm.contacts
          this.contactsOriginal = originalForm.contacts
          // Borro los contactos para que no se evalua en el primer isEqual
          delete this.currentForm.contacts
          delete originalForm.contacts
          // Evaluo el formulario actual y original sin los contactos
          // Evaluo a aparte los contactos
          if (isEqual(this.currentForm, originalForm) && isEqual(this.contactsForm, this.contactsOriginal)) 
          {
            this.isOriginalForm = true
          } 
          else 
          {
            this.isOriginalForm = false
          }
        }
        
      },
      deep: true
    },
    refresh_company_data (value) {
      if (this.isAdmin && !!value) {
        this.company_data.company.active_reason_id = !!value.activated ? value.reason_id.toString() : null
        this.company_data.company.activated = !!value.activated ? 1 : 0
        this.getBillingDataCompany({ validateCompanyStatus: true })
      }
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      const date = new Date()
      this.maxMonth = date.setMonth(date.getMonth() + 2)
      this.setFields()
      this.setSchema()
      this.verifySessionAndCompanyData()
    },
    verifySessionAndCompanyData() {
      if (!!this.setUserInSession && this.company_data?.company) {
        this.setRoleUsers()
      } else {
        setTimeout(() => {
          this.verifySessionAndCompanyData()
        }, 200)
      }
    },
    setRoleUsers () {
      this.isAdmin = ['admin', 'superadmin'].includes(this.$session.get('cas_user').role)
      this.setFieldsDinamic()
      this.getCompanyInfoBilling()
      this.setDniLabel(this.company_data.company?.country.code)
    },
    setFieldsDinamic () {
      const indexDirectBilling = this.fields.findIndex(el => el.name === 'directBilling')
      const indexShowInvoices = this.fields.findIndex(el => el.name === 'showInvoices')
      if (indexShowInvoices === -1 && this.isAdmin) this.fields.unshift({fieldType: 'FieldCheckbox', name: 'showInvoices', label: '', containerClass: this.isAdmin ? 'col-md-10 mb-1' : 'col-md-9 mb-1', align: 'h', options: this.optionsInvoices, disabled: true})
      if (this.company_data.company.type.text === 'Seller' && indexDirectBilling === -1 && this.isAdmin)  this.fields.unshift({fieldType: 'FieldCheckbox', name: 'directBilling', label: '', containerClass: this.isAdmin ? 'col-md-2 mb-1' : 'col-md-12 mb-1', align: 'h', options: this.optionsBilling, disabled: true, change: this.handleShowFormBilling})
      if (this.isAdmin) {
        const indexRequiredOc = this.fields.findIndex(el => el.name === 'required_oc')
        const indexPlaceName = this.fields.findIndex(el => el.name === 'place_name')
        const indexTaxIdentification = this.fields.findIndex(el => el.name === 'taxIdentificationNumber')
        const indexCreditDays = this.fields.findIndex(el => el.name === 'credit_days')
        this.fields.splice(indexRequiredOc + 1, 0, {fieldType: 'FieldTextarea', useLabel: true, name: 'billingNote', label: 'Notas Sobre Facturación', containerClass: 'container-info col-12 mt-2', placeholder: 'Ingrese notas', tooltip: {text: 'Uso Interno'}, disabled: true})
        this.fields[indexPlaceName].containerClass = 'container-info col-md-3'
        this.fields[indexTaxIdentification].containerClass = 'container-info col-md-3'
        this.fields.splice(indexCreditDays + 1, 0, 
          {
            fieldType: 'FieldDatepicker', name: 'overdue_payment_limit', label: 'Fecha límite de pago', useLabel: true, containerClass: 'container-info col-md-3',
            disabled: true,
            clearable: false,
            propsVCalendar: { 'min-date': new Date(), 'max-date': this.maxMonth },
            datePattern: '/',
            validation: '',
            placeholder: 'dd/mm/yyyy',
            tooltip: { text: 'La "Fecha límite de pago" solo puede ser ingresada cuando la compañía se encuentre "Activa" y con "Motivo de la activación" en "Cuenta activada por compromiso de pago", será considerada para la DESACTIVACIÓN AUTOMATICA de la compañía si una vez cumplida se detectan facturas vencidas, y se limpia al detectarse pago de las deudas vencidas.' }
          }
        )
      }
    },
    setOverduePaymentInput () {
      const index = this.fields.findIndex(el => el.name === 'overdue_payment_limit')
      this.fields[index].validation = 'requiredDate'
      this.keyFormRender++
      this.enabledFieldOverdue = true
    },
    setDniLabel(countryCode) {
      const toLowerCaseCountryCode = countryCode.toLowerCase()
      const texts = this.$i18nImport(`${toLowerCaseCountryCode}.es`)[`${toLowerCaseCountryCode}.es`]
      const indexDNI = this.fields.findIndex(el => el.name === 'taxIdentificationNumber')
      if (indexDNI !== -1) {
        this.fields[indexDNI].label = texts.WORD_DNI
        this.fields[indexDNI].placeholder = texts.FORMAT_DNI
        this.fields[indexDNI].validation = `required|taxIdValidator:${toLowerCaseCountryCode}`
        this.fields[indexDNI].useLabel = false
        this.fields[indexDNI].useSkeleton = false
        if (toLowerCaseCountryCode === this.CONDITIONS.CHILE) this.fields[indexDNI].specialVerification = this.rutMask
        this.keyFormRender++
      }
    },
    onErrorBillingData(err, type) {
      const {subtitle, detail} = this.handlerStandartError(err)
      this.$alert(`${subtitle}<small>${detail}</small>`, null, `Ocurrió un problema al ${type} los datos`)
    },
    getCompanyInfoBilling () {
      this.$store.dispatch('fetchServiceStatusOnError', {
        name: 'getCompanyInfoBilling',
        params: { company_id: this.company_id },
        hideAlert: true,
        onSuccess: (response) => this.hasBillignData(response.data),
        onError: (e) => this.onErrorBillingData(e, this.CONDITIONS.LOAD)
      })
    },
    hasBillignData(data) {
      if (!!data.hasBusinessData) {
        this.getBillingDataCompany({ validateCompanyStatus: true })
      } else {
        this.$emit('setTabAuthorityContacts', true)
        this.createBillingData = true
        if (this.company_data.company.type.text === 'Seller' && !this.isAdmin) this.$emit('notDirectBilling', true)
      }
    },
    getBillingDataCompany ({ validateCompanyStatus = false } = {}) {
      this.$store.dispatch('fetchServiceStatusOnError', {
        name: 'getManageBillingDataCompany',
        params: { company_id: this.company_id },
        hideAlert: true,
        onSuccess: (response) => this.setFormBilling({data: response.data, validateCompanyStatus}),
        onError: (e) => this.onErrorBillingData(e, this.CONDITIONS.LOAD)
      })
    },
    parsedBilling (data) {
      return { 
        taxIdentificationNumber: data.taxIdentificationNumber || '',
        business_name: data.business_name || '',
        business_activity: data.business_activity || '',
        credit_days: data.credit_days || '',
        overdue_payment_limit: data.overdue_payment_limit ? this.$options.filters.dateToFullDate(data.overdue_payment_limit) : null,
        required_oc: data.required_oc || false,
        directBilling: data.directBilling || false,
        showInvoices: data.showInvoices || false,
        billingNote: data.billingNote || '',
        place_name: data.place_name || '',
        address: data.address || '',
        contacts: data.contacts || [],
        city: data.city || ''
      }
    },
    setFormBilling ({data, validateCompanyStatus}) {
      // Esconder campos
      this.notDirectBilling = !data?.directBilling && this.company_data.company.type.text === 'Seller' 
      this.$emit('notDirectBilling', this.notDirectBilling)

      if (this.company_data.company.type.text === 'Seller') this.handleBillingDisplay(!this.notDirectBilling)

      this.createBillingData = false
      this.form = this.parsedBilling(data)
      this.originalForm = _.cloneDeep({ ...this.parsedBilling(data) })
      this.setRows(data)
      if (this.isAdmin && validateCompanyStatus) this.setDisabledFieldOverdue(data)
    },
    // Validando tanto en que estado esta la empresa y en que estado esta la empresa pero en facturación
    setDisabledFieldOverdue (data) {
      // Variables para validar que la empresa tenga el mismo estado y razon de activación
      const reason_active_company = this.company_data.active_reasons.filter(reason => Number(reason.id) === Number(this.company_data.company.active_reason_id))[0]
      const reason_active_name_company = reason_active_company?.description?.toLowerCase() || null
      const reason_active_name_company_billing = data.company?.activationStatusDesc?.toLowerCase() || null
      const active_company = this.company_data.company.activated === 1
      const active_company_billing = data.company.isActive

      if (active_company !== active_company_billing || reason_active_name_company !== reason_active_name_company_billing) {
        const dataToastNotification = {
          title: 'Importante',
          message: 'Se han detectado diferencias en el estado de su empresa con la información del sistema de facturación. Por favor, póngase en contacto con el equipo de soporte para más detalle.',
          variant: 'warning',
          icon: 'warning'
        }
        this.showToastNotificationError(dataToastNotification)
      }
      else if (!!active_company && 
          !!active_company_billing && 
          reason_active_name_company === this.CONDITIONS.PAYMENTCOMMITMENT && 
          reason_active_name_company_billing === this.CONDITIONS.PAYMENTCOMMITMENT
      ) {
        this.setOverduePaymentInput()
      }
    },
    handleBillingDisplay (type) {
      const alwaysShowField = ['directBilling']

      const indexDirectBilling = this.fields.findIndex(el => el.name === alwaysShowField[0])
      if (indexDirectBilling !== -1) this.fields[indexDirectBilling].disabled = type

      const fieldsFilter = this.fields.filter(field => !alwaysShowField.includes(field.name))
      fieldsFilter.map(item => {
        if (type) {
          item.containerClass = item.containerClass.split(' ').filter(className => className !== 'hide').join(' ')
          if (!item.containerClass.includes('show')) {
            item.containerClass += ' show'
          }
        } else {
          item.containerClass = item.containerClass.split(' ').filter(className => className !== 'show').join(' ')
          if (!item.containerClass.includes('hide')) {
            item.containerClass += ' hide'
          }
        }
      })
      this.showBtn.edit = type
    },
    setRows (data) {
      this.rows = data.contacts.map(contact => {
        return {
          ...contact,
          id: contact.email
        }
      })
    },
    setSchema () {
      this.schema = [
        {label: 'Nombre', sortable: true, key: 'name'},
        {label: 'Cargo', sortable: true, key: 'jobTitle'},
        {label: 'Mail', sortable: true, key: 'email'},
        {label: 'Teléfono', sortable: true, key: 'phoneNumber'}
      ]
      this.actions = [
        { action: id => this.openModalEditContactBilling(id), icon: 'Edit2Icon', color: 'primary', text: 'Editar' },
        { action: id => this.confirmDeleteContactBilling(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
      ]
    },
    setFields () {
      this.fields = [
        {fieldType: 'FieldSelect', useSkeleton: false, useLabel: true, name: 'place_name', label: 'Ubicación (Comuna, Colonia, Municipio ó Distrito)', validation: 'required', containerClass: 'container-info col-md-4', disabled: true, clearable: true, searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600, persistSearch: true }},
        {fieldType: 'FieldInput', useLabel: true, name: 'address', label: 'Dirección', containerClass: 'container-info col-md-3', disabled: true, validation: 'required'},
        {fieldType: 'FieldInput', name: 'taxIdentificationNumber', label: 'TaxId', useLabel: false, useSkeleton: false, containerClass: 'container-info col-md-4', disabled: true},
        {fieldType: 'FieldInput', useLabel: true, name: 'business_name', label: 'Razón Social', containerClass: 'container-info col-md-3', disabled: true, validation: 'required'},
        {fieldType: 'FieldInput', useLabel: true, name: 'business_activity', label: 'Giro', containerClass: 'container-info col-md-3', disabled: true, validation: 'required'},
        {fieldType: 'FieldInput', useLabel: true, name: 'credit_days', type: 'number', label: 'Días de crédito', containerClass: 'container-info col-md-3', disabled: true, validation: 'required'},
        {fieldType: 'FieldCheckbox', name: 'required_oc', label: '', containerClass: 'col-md-3 container-info justify-content-center mb-0 mt-50', align: 'h', options: this.optionsOC, disabled: true},
        {name: 'contacts', useSlot: true, containerClass: 'col-sm-12 container-info pt-1'}
      ]
    },
    rutMask (value) {
      //Autoformatear el rut a la hora de ingresar los datos
      return (value.length <= 1) ? value : new String(this.$options.filters.formatRut(value))
    },
    handleShowFormBilling (name, value) {
      if (!!this.createBillingData) return
      this.handleBillingDisplay(value)
      if (!!value) {
        this.enabledFields()
      } 
      else {
        this.showBtn.saved = !this.notDirectBilling 
      }
    },
    enabledFields () {
      this.disabledFields(false)
      this.showBtn.edit = false
      this.showBtn.saved = true
    },
    disabledFields (disabled) {
      this.fields.map(item => {
        if (item.name === 'overdue_payment_limit' && !this.enabledFieldOverdue) {
          item.disabled = true
        } else {
          item.disabled = disabled
        }
      })
      this.conditionsActions(disabled)
    },
    conditionsActions (disabled) {
      const indexActions = this.schema.findIndex(el => el.key === 'actions')
      if (!!disabled) {
        this.schema.pop()
      } else if (!disabled && indexActions === -1) {
        this.schema.push({label: 'Acciones', key: 'actions', class: 'text-center', style: { width: '20%' } })
      } 
    },
    savedBillingContact (data, edit, id) {
      if (!!edit) {
        this.updateBillingContact(data, id)
      }
      else {
        this.addBillingContact(data)
      }
    },
    addBillingContact (data) {
      this.form.contacts.push(data)
      this.setRows(this.form)
      this.$refs.modalBillingContact.loading.first = false
      this.$bvModal.hide('modalBillingContact')
    },
    updateBillingContact (data, id) {
      this.form.contacts = this.form.contacts.map(contact => contact.email === id ? data : contact)
      this.setRows(this.form)
      this.$refs.modalBillingContact.loading.first = false
      this.$bvModal.hide('modalBillingContact')
    },
    confirmDeleteContactBilling (id) {
      this.$confirm(this.$t('¿Está seguro que desea eliminar el contacto?'), () => this.deleteBillingContact(id))
    },
    deleteBillingContact (id) {
      const deleteRow = this.rows.find(email => id === email.id)
      this.form.contacts = this.form.contacts.filter(contact => contact.email !== deleteRow.email)
      this.setRows(this.form)
    },
    validateIfExistsEmails(emails) {
      if (emails?.length > 0) {
        return true
      } else {
        const dataToastNotification = {
          title: 'Oops',
          message: 'Debe incluir al menos un contacto de facturación',
          variant: 'danger',
          icon: 'warning'
        }
        this.showToastNotificationError(dataToastNotification)
        return false
      }
    },
    setPayloadCreateBilling (data) {
      return {
        taxIdentificationNumber: data.taxIdentificationNumber,
        address: data.address,
        place_name: data.place_name.name,
        city: data.city,
        business_name: data.business_name,
        business_activity: data.business_activity,
        credit_days: parseInt(data.credit_days),
        contacts: data.contacts,
        required_oc: !!data.required_oc,
        showInvoices: !!data.showInvoices,
        billingNote: data.billingNote,
        directBilling: this.company_data.company.type.text === 'Seller' ? !!data.directBilling : true,
        overdue_payment_limit: this.$options.filters.moment(data.overdue_payment_limit, 'YYYY-MM-DD') || null
      }
    },
    setPayloadEditBilling (data) {
      const onlyFieldChange = this.m_findChangedFields(this.currentForm, this.originalForm)
      if (this.$options.filters.moment(this.currentForm.overdue_payment_limit, 'YYYY-MM-DD') === this.$options.filters.moment(this.originalForm.overdue_payment_limit, 'YYYY-MM-DD')) {
        // Lo borro porque esta igual pero la función no lo valida bien
        delete onlyFieldChange.overdue_payment_limit
      }
      if (!isEqual(this.contactsForm, this.contactsOriginal)) onlyFieldChange.contacts = data.contacts
      if (onlyFieldChange.place_name) onlyFieldChange.place_name = data.place_name.name
      if (onlyFieldChange.credit_days) onlyFieldChange.credit_days = parseInt(data.credit_days)
      if (onlyFieldChange.overdue_payment_limit) onlyFieldChange.overdue_payment_limit = this.$options.filters.moment(data.overdue_payment_limit, 'YYYY-MM-DD')
      return onlyFieldChange
    },
    handleSubmit (data) {
      if (!this.validateIfExistsEmails(this.rows)) return
      this.loading.savedChangeBilling = true
      this.showOverlay = true
      const params = {company_id: this.company_id}
      const manage = this.createBillingData ? this.setPayloadCreateBilling(data) : this.setPayloadEditBilling(data)
      const nameService = !!this.createBillingData ? 'saveManageBillingDataCompany' : 'updateManageBillingDataCompany'
      this.$store.dispatch('fetchServiceStatusOnError', { name: nameService, queryParams: manage, params, onSuccess: (resp) => { this.onSuccessUpdatingData(resp.data) }, onError: err => this.errorUpdatingData(err) })
    },
    onSuccessUpdatingData (data) {
      this.getBillingDataCompany()
      this.showOverlay = false
      this.loading.savedChangeBilling = false
      this.disabledFields(true)
      this.showBtn.edit = true
      this.showBtn.saved = false 
      this.$newSuccess('Excelente', `Se ${this.createBillingData ? 'crearon' : 'actualizaron'} los datos de facturación`)
      if (this.company_data.company.type.text === 'Seller') this.$emit('validateAuthorityContacts', data.directBilling)
    },
    errorUpdatingData (err) {
      this.loading.savedChangeBilling = false
      this.showOverlay = false
      this.onErrorBillingData(err, this.CONDITIONS.SAVE)
    },
    validateForm () {
      this.$refs.formRenderBilling.checkForm()
    },
    openModalCreateBilling () {
      this.currentBillingContact = {}
      this.$bvModal.show('modalBillingContact')
    },
    openModalEditContactBilling (id) {
      const contact = this.rows.filter(row => row.id === id)[0]
      this.currentBillingContact = {...contact, id}
      this.$bvModal.show('modalBillingContact')
    },
    async setPlaces(value) {
      try {
        const params = { country_id: this.company_data.company?.country_id }
        const queryParams = { str: value }
        const resp = await this.baseService.callService('getFullPlaces', queryParams, params)
        const response = resp.places.map(el => ({...el, text: el.full_name, valid: true}))
        return response
      } catch (err) {
        return err
      }
    },
    showToastNotificationError(dataToastNotification) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: dataToastNotification.title,
          icon: dataToastNotification.icon,
          variant: dataToastNotification.variant,
          text: dataToastNotification.message
        }
      })
    }
  }
}    
</script>
<style lang='scss' scoped>
.custom-position-edit-billing {
  top: -25px;
  right: 0;
}
.table-billing {
  .table-render-skeleton{
    overflow-x: hidden;
  }
}
.show {
  display: flex !important;
}
</style>