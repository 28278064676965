<template>
    <b-modal 
      id="modalBillingContact" 
      :title="$t(`${!editBillingContact ? 'Añadir contacto de facturación' : 'Editar contacto de facturación'}`)" 
      modal-class="custom-dialog dialog-600" 
      :ok-title="$t(`${!editBillingContact ? 'Añadir' : 'Guardar'}`)" 
      ok-variant="warning" 
      ok-only 
      no-close-on-esc 
      no-close-on-backdrop 
      centered 
      @close="close" 
      @ok="ok" 
      :ok-disabled="loading.first || loading.someMail"
      >
      <form-render
        :form.sync="form" 
        :fields="fields" 
        @send="onAccept" 
        containerButtonsClass="col-md-4 col-xl-3"
        class="mt-1"
        ref="formRenderBillingContact"
        :key="keyFormRender" 
        />
    </b-modal>
</template> 
<script>
export default {
  name: 'modal-change-status',
  props: ['billingContact', 'rows'],
  data () {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      loading: {
        first: false,
        someMail: false
      },
      editBillingContact: false,
      safeMail: ''
    }
  },
  watch: {
    billingContact (value) {
      this.form = {
        ...value
      }
      this.safeMail = this.form.email
      // Validamos si es una creación de empleado o edición de empleado
      this.editBillingContact = !!value.id
      this.loading.first = false
      this.loading.someMail = false
      this.fields.find(field => field.name === 'email').msgError = ''
      this.keyFormRender++
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields = [
        {fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese Nombre y Apellido', validation: 'required'},
        {fieldType: 'FieldInput', name: 'jobTitle', label: 'Cargo', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese Cargo'},
        {fieldType: 'FieldInput', name: 'email', type: 'email', change: this.onChangeEmail, label: 'Mail', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese Mail de contacto', validation: 'required|email', msgError: ''},
        {fieldType: 'FieldInput', name: 'phoneNumber', label: 'Teléfono', type: 'number', containerClass: 'col-md-12 container-info', placeholder: 'Ingrese Teléfono de contacto'}
       
      ]
      this.loading.first = false
    },
    onChangeEmail() {
      const existEmailInContact = this.rows?.some(contact => contact.email === this.form.email)
      const isSafeMail = this.safeMail === this.form.email
      if (existEmailInContact && !isSafeMail) {
        this.fields.find(field => field.name === 'email').msgError = 'El correo ya existe'
        this.loading.someMail = true
      } else {
        this.fields.find(field => field.name === 'email').msgError = ''
        this.loading.someMail = false
      }
    },
    close () {
      this.$emit('update:billingContact', {})
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderBillingContact.checkForm()
    },
    onAccept (data) {
      const payload = {
        ...data
      }
      this.loading.first = true
      const keys = ['jobTitle', 'phoneNumber']
      keys.forEach(key => {
        if (data[key] === '' || data[key] === undefined || data[key] === null) {
          payload[key] = null
        }
      })
      this.$emit('savedBillingContact', payload, this.editBillingContact, this.form?.id)   
    }
  }
}
</script>

<style>

</style>